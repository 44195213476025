import { default as cookiesQpgRDEee1uMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue?macro=true";
import { default as cookiesozVvEZzwklMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue?macro=true";
import { default as index5XRznUjiOKMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue?macro=true";
import { default as legalCDDxmMVSPmMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue?macro=true";
import { default as portfolio_45aboutMWFACu3fXXMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue?macro=true";
import { default as portfolio_45feesowtEAp5aJWMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorspIzLMos1X1Meta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuers5ZI4jqt2tVMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue?macro=true";
import { default as portfolio_45members2SFKCPiVOfMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue?macro=true";
import { default as index0Dho44cdtqMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reit9sDIHHyWOMMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnerswxc7jvaGKFMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue?macro=true";
import { default as privacyQGP9ezv8LRMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue?macro=true";
import { default as indexW9FTXpJW8PMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue?macro=true";
import { default as legalMytrVZ7WdAMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue?macro=true";
import { default as portfolio_45aboutB0NRXs3fOWMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue?macro=true";
import { default as portfolio_45feesEAVRfzx8iPMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorsJ8AYadXTWIMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersJpO4TNWyONMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersS36puuuYS2Meta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue?macro=true";
import { default as indexr1RXDxQ9x4Meta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitVRNVjzLWw3Meta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersj1p9xNy0YxMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue?macro=true";
import { default as privacyuXrTOygLVuMeta } from "/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue?macro=true";
export default [
  {
    name: cookiesQpgRDEee1uMeta?.name ?? "cookies",
    path: cookiesQpgRDEee1uMeta?.path ?? "/cookies",
    meta: cookiesQpgRDEee1uMeta || {},
    alias: cookiesQpgRDEee1uMeta?.alias || [],
    redirect: cookiesQpgRDEee1uMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiesozVvEZzwklMeta?.name ?? "es-cookies",
    path: cookiesozVvEZzwklMeta?.path ?? "/es/cookies",
    meta: cookiesozVvEZzwklMeta || {},
    alias: cookiesozVvEZzwklMeta?.alias || [],
    redirect: cookiesozVvEZzwklMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue").then(m => m.default || m)
  },
  {
    name: index5XRznUjiOKMeta?.name ?? "es",
    path: index5XRznUjiOKMeta?.path ?? "/es",
    meta: index5XRznUjiOKMeta || {},
    alias: index5XRznUjiOKMeta?.alias || [],
    redirect: index5XRznUjiOKMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue").then(m => m.default || m)
  },
  {
    name: legalCDDxmMVSPmMeta?.name ?? "es-legal",
    path: legalCDDxmMVSPmMeta?.path ?? "/es/legal",
    meta: legalCDDxmMVSPmMeta || {},
    alias: legalCDDxmMVSPmMeta?.alias || [],
    redirect: legalCDDxmMVSPmMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutMWFACu3fXXMeta?.name ?? "es-portfolio-about",
    path: portfolio_45aboutMWFACu3fXXMeta?.path ?? "/es/portfolio-about",
    meta: portfolio_45aboutMWFACu3fXXMeta || {},
    alias: portfolio_45aboutMWFACu3fXXMeta?.alias || [],
    redirect: portfolio_45aboutMWFACu3fXXMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesowtEAp5aJWMeta?.name ?? "es-portfolio-fees",
    path: portfolio_45feesowtEAp5aJWMeta?.path ?? "/es/portfolio-fees",
    meta: portfolio_45feesowtEAp5aJWMeta || {},
    alias: portfolio_45feesowtEAp5aJWMeta?.alias || [],
    redirect: portfolio_45feesowtEAp5aJWMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorspIzLMos1X1Meta?.name ?? "es-portfolio-investors",
    path: portfolio_45investorspIzLMos1X1Meta?.path ?? "/es/portfolio-investors",
    meta: portfolio_45investorspIzLMos1X1Meta || {},
    alias: portfolio_45investorspIzLMos1X1Meta?.alias || [],
    redirect: portfolio_45investorspIzLMos1X1Meta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuers5ZI4jqt2tVMeta?.name ?? "es-portfolio-issuers",
    path: portfolio_45issuers5ZI4jqt2tVMeta?.path ?? "/es/portfolio-issuers",
    meta: portfolio_45issuers5ZI4jqt2tVMeta || {},
    alias: portfolio_45issuers5ZI4jqt2tVMeta?.alias || [],
    redirect: portfolio_45issuers5ZI4jqt2tVMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45members2SFKCPiVOfMeta?.name ?? "es-portfolio-members",
    path: portfolio_45members2SFKCPiVOfMeta?.path ?? "/es/portfolio-members",
    meta: portfolio_45members2SFKCPiVOfMeta || {},
    alias: portfolio_45members2SFKCPiVOfMeta?.alias || [],
    redirect: portfolio_45members2SFKCPiVOfMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: index0Dho44cdtqMeta?.name ?? "es-portfolio-news",
    path: index0Dho44cdtqMeta?.path ?? "/es/portfolio-news",
    meta: index0Dho44cdtqMeta || {},
    alias: index0Dho44cdtqMeta?.alias || [],
    redirect: index0Dho44cdtqMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reit9sDIHHyWOMMeta?.name ?? "es-portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reit9sDIHHyWOMMeta?.path ?? "/es/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reit9sDIHHyWOMMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reit9sDIHHyWOMMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reit9sDIHHyWOMMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnerswxc7jvaGKFMeta?.name ?? "es-portfolio-partners",
    path: portfolio_45partnerswxc7jvaGKFMeta?.path ?? "/es/portfolio-partners",
    meta: portfolio_45partnerswxc7jvaGKFMeta || {},
    alias: portfolio_45partnerswxc7jvaGKFMeta?.alias || [],
    redirect: portfolio_45partnerswxc7jvaGKFMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyQGP9ezv8LRMeta?.name ?? "es-privacy",
    path: privacyQGP9ezv8LRMeta?.path ?? "/es/privacy",
    meta: privacyQGP9ezv8LRMeta || {},
    alias: privacyQGP9ezv8LRMeta?.alias || [],
    redirect: privacyQGP9ezv8LRMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexW9FTXpJW8PMeta?.name ?? "index",
    path: indexW9FTXpJW8PMeta?.path ?? "/",
    meta: indexW9FTXpJW8PMeta || {},
    alias: indexW9FTXpJW8PMeta?.alias || [],
    redirect: indexW9FTXpJW8PMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalMytrVZ7WdAMeta?.name ?? "legal",
    path: legalMytrVZ7WdAMeta?.path ?? "/legal",
    meta: legalMytrVZ7WdAMeta || {},
    alias: legalMytrVZ7WdAMeta?.alias || [],
    redirect: legalMytrVZ7WdAMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutB0NRXs3fOWMeta?.name ?? "portfolio-about",
    path: portfolio_45aboutB0NRXs3fOWMeta?.path ?? "/portfolio-about",
    meta: portfolio_45aboutB0NRXs3fOWMeta || {},
    alias: portfolio_45aboutB0NRXs3fOWMeta?.alias || [],
    redirect: portfolio_45aboutB0NRXs3fOWMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesEAVRfzx8iPMeta?.name ?? "portfolio-fees",
    path: portfolio_45feesEAVRfzx8iPMeta?.path ?? "/portfolio-fees",
    meta: portfolio_45feesEAVRfzx8iPMeta || {},
    alias: portfolio_45feesEAVRfzx8iPMeta?.alias || [],
    redirect: portfolio_45feesEAVRfzx8iPMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorsJ8AYadXTWIMeta?.name ?? "portfolio-investors",
    path: portfolio_45investorsJ8AYadXTWIMeta?.path ?? "/portfolio-investors",
    meta: portfolio_45investorsJ8AYadXTWIMeta || {},
    alias: portfolio_45investorsJ8AYadXTWIMeta?.alias || [],
    redirect: portfolio_45investorsJ8AYadXTWIMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersJpO4TNWyONMeta?.name ?? "portfolio-issuers",
    path: portfolio_45issuersJpO4TNWyONMeta?.path ?? "/portfolio-issuers",
    meta: portfolio_45issuersJpO4TNWyONMeta || {},
    alias: portfolio_45issuersJpO4TNWyONMeta?.alias || [],
    redirect: portfolio_45issuersJpO4TNWyONMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersS36puuuYS2Meta?.name ?? "portfolio-members",
    path: portfolio_45membersS36puuuYS2Meta?.path ?? "/portfolio-members",
    meta: portfolio_45membersS36puuuYS2Meta || {},
    alias: portfolio_45membersS36puuuYS2Meta?.alias || [],
    redirect: portfolio_45membersS36puuuYS2Meta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: indexr1RXDxQ9x4Meta?.name ?? "portfolio-news",
    path: indexr1RXDxQ9x4Meta?.path ?? "/portfolio-news",
    meta: indexr1RXDxQ9x4Meta || {},
    alias: indexr1RXDxQ9x4Meta?.alias || [],
    redirect: indexr1RXDxQ9x4Meta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitVRNVjzLWw3Meta?.name ?? "portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitVRNVjzLWw3Meta?.path ?? "/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitVRNVjzLWw3Meta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitVRNVjzLWw3Meta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitVRNVjzLWw3Meta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersj1p9xNy0YxMeta?.name ?? "portfolio-partners",
    path: portfolio_45partnersj1p9xNy0YxMeta?.path ?? "/portfolio-partners",
    meta: portfolio_45partnersj1p9xNy0YxMeta || {},
    alias: portfolio_45partnersj1p9xNy0YxMeta?.alias || [],
    redirect: portfolio_45partnersj1p9xNy0YxMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyuXrTOygLVuMeta?.name ?? "privacy",
    path: privacyuXrTOygLVuMeta?.path ?? "/privacy",
    meta: privacyuXrTOygLVuMeta || {},
    alias: privacyuXrTOygLVuMeta?.alias || [],
    redirect: privacyuXrTOygLVuMeta?.redirect,
    component: () => import("/codebuild/output/src3665994162/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue").then(m => m.default || m)
  }
]